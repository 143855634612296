import { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";

import "./datepicker.scss";
import "./Calendar.scss";

registerLocale("ru", ru);

const Calendar = ({
  minDate,
  dateFormat,
  placeholderText,
  selected,
  onChange,
  dropdownMode,
  showMonthDropdown,
  showYearDropdown,
}) => {
  const [showMonthPicker, setShowMonthPicker] = useState(false);
  const [showYearPicker, setShowYearPicker] = useState(false);

  const months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];

  const handleMonthPickerClick = () => {
    setShowYearPicker(false);
    setShowMonthPicker(!showMonthPicker);
  };

  const handleYearPickerClick = () => {
    setShowMonthPicker(false);
    setShowYearPicker(!showYearPicker);
  };

  const renderCustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    decreaseYear,
    increaseYear,
    changeMonth,
    changeYear,
  }) => {
    const canDecreaseMonth = minDate ? date >= minDate : true;

    return (
      <div className="custom-header">
        <button className="navigation-button" onClick={decreaseMonth} disabled={!canDecreaseMonth}>&lt;</button>
        <div className="custom-header-buttons">
          <button onClick={handleMonthPickerClick}>{months[date.getMonth()]}</button>
          <button onClick={handleYearPickerClick}>{date.getFullYear()}</button>
        </div>
        <button className="navigation-button" onClick={increaseMonth}>&gt;</button>

        {showMonthPicker && (
          <div className="picker-dropdown">
            <div className="picker-dropdown-grid">
              {months.map((month, idx) => {
                const isDisabled = minDate && 
                new Date(date.getFullYear(), idx) < new Date(minDate.getFullYear(), minDate.getMonth());
              
              return (
                <button
                  key={month}
                  onClick={() => {
                    if (!isDisabled) {
                      changeMonth(idx);
                      setShowMonthPicker(false);
                    }
                  }}
                  className={`
                    ${date.getMonth() === idx ? "selected" : ""}
                    ${isDisabled ? "disabled" : ""}
                  `}
                  disabled={isDisabled}
                >
                  {month}
                </button>
              )})}
            </div>
          </div>
        )}

        {showYearPicker && (
          <div className="picker-dropdown">
            <div className="picker-dropdown-grid">
              {Array.from({ length: 12 }, (_, i) => {
                const year = 2024 + i;
                return (
                  <button
                    key={year}
                    onClick={() => {
                      changeYear(year);
                      setShowYearPicker(false);
                    }}
                    className={date.getFullYear() === year ? "selected" : ""}
                  >
                    {year}
                  </button>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <DatePicker
      minDate={minDate}
      dateFormat={dateFormat}
      placeholderText={placeholderText}
      selected={selected}
      onChange={(date) => {
        onChange(date);
        setShowMonthPicker(false);
        setShowYearPicker(false);
      }}
      dropdownMode={dropdownMode}
      showMonthDropdown={showMonthDropdown}
      showYearDropdown={showYearDropdown}
      onCalendarClose={() => {
        setShowMonthPicker(false);
        setShowYearPicker(false);
      }}
      renderCustomHeader={renderCustomHeader}
      locale="ru"
    />
  );
};

export default Calendar;
