import { useState } from "react";

import Button from "../Button/Button";

import "./Pagination.scss";

const Pagination = ({
  totalItems,
  itemsPerPage,
  onPageChange,
  isMobile = false,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedItems, setDisplayedItems] = useState(itemsPerPage);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPageNumbers = () => {
    const pages = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
      return pages;
    }

    if (currentPage <= 3) {
      return [1, 2, 3, "..", totalPages];
    }

    if (currentPage > totalPages - 4) {
      return [
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    }

    return [
      currentPage - 2,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      currentPage + 2,
    ];
  };

  const handlePageChange = (page) => {
    if (page !== "..") {
      setCurrentPage(page);
      onPageChange(page);
    }
  };

  const handleShowMore = () => {
    const newDisplayedItems = displayedItems + itemsPerPage;
    setDisplayedItems(Math.min(newDisplayedItems, totalItems));
    onPageChange(Math.ceil(newDisplayedItems / itemsPerPage));
  };

  if (isMobile) {
    return (
      <Button
        title="Показать ещё"
        className="pagination__show-more"
        onClick={handleShowMore}
        disabled={displayedItems >= totalItems}
      >
        Показать ещё
      </Button>
    );
  }

  return (
    <div className="pagination">
      <button
        type="button"
        aria-label="Назад"
        className={`pagination__arrow pagination__arrow-back ${currentPage === 1 ? "disabled" : ""}`}
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />

      <div className="pagination__numbers">
        {getPageNumbers().map((pageNumber, index) => pageNumber === ".." ?
            <span key="dots" className="pagination__dots">..</span>
          :
            <button key={pageNumber} className={`pagination__number ${currentPage === pageNumber ? "active" : ""}`} onClick={() => handlePageChange(pageNumber)}>{pageNumber}</button>
        )}
      </div>

      <button
        type="button"
        aria-label="Вперед"
        className={`pagination__arrow pagination__arrow-next ${currentPage === totalPages ? "disabled" : ""}`}
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
    </div>
  );
};

export default Pagination;
