import { observer } from "mobx-react";

import Calendar from "components/UI/Calendar/Calendar";

import "./DateFilter.scss";

const DateFilter = observer(({ store }) => {
  return (
    <div className="invited__date-filter">
      <p>Начисления за период с:</p>
      <Calendar
        selected={store.startDate}
        onChange={(date) => store.setStartDate(date)}
        minDate={new Date(2024, 9, 1)}
        dateFormat="dd.MM.yyyy"
        placeholderText="дд.мм.гггг"
        dropdownMode="select"
      />
      <p>по</p>
      <Calendar
        selected={store.endDate}
        onChange={(date) => store.setEndDate(date)}
        minDate={new Date(2024, 9, 1)}
        dateFormat="dd.MM.yyyy"
        placeholderText="дд.мм.гггг"
        dropdownMode="select"
      />
    </div>
  );
});

export default DateFilter;
