import { runInAction } from "mobx";
import { Link, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";

import AppStore from "AppStore";

import "./Breadcrumbs.scss";

const Breadcrumbs = observer(() => {
  const { pathname } = useLocation();

  const hiddenPaths = [
    "/",
    "/projects",
    "/referral",
    "/payment-form",
    "/signin",
    "/signup",
    "/restore",
    "/reset",
  ];

  if (hiddenPaths.includes(pathname) || pathname.includes('/invite/'))
    return null;

  // endpoints и их названия на страницах
  const breadcrumbMap = {
    blog: "Блог",
    "nakrutka-pf-osnovnye-metody": "Что такое накрутка поведенческих факторов: основные методы",
    "nuzhno-li-krutit-pf-dlya-google": "Нужно ли крутить ПФ для Google?",
  };

  let crumbs = [];

  const marketInfo = AppStore.findMarketStoreAndCalculatorByPath(pathname);
  if (marketInfo) {
    const { i, j } = marketInfo;
    runInAction(() => {
      AppStore.selectMarketStoreAndCalculatorByIndexes(i, j);
    });
    const calculator = AppStore.currentMarketStore.currentCalculator;
    const { mode } = calculator;
    const introTitle = mode?.intro?.title;

    crumbs = [
      { title: "Услуги", path: "/" },
      { title: introTitle, path: pathname }
    ];
  } else {
    let currentPath = "";
    const pathSegments = pathname.split("/").filter((segment) => segment !== "");
    
    crumbs = pathSegments.map((segment) => {
      currentPath += `/${segment}`;
      return {
        title: breadcrumbMap[segment] || segment,
        path: currentPath
      };
    });
  }

  const handleServicesClick = (e) => {
    e.preventDefault();
    AppStore.setIsDropdownLinksOpen(true);
  };

  return (
    <div className="breadcrumbs">
      {crumbs.map((crumb, index) => (
        <div className="crumb" key={index}>
          {index === crumbs.length - 1 ? (
            <span>{crumb.title}</span>
          ) : crumb.title === "Услуги" ? (
            <Link to="/" onClick={handleServicesClick}>{crumb.title}</Link>
          ) : (
            <Link to={crumb.path}>{crumb.title}</Link>
          )}
        </div>
      ))}
    </div>
  );
});

export default Breadcrumbs;