import { observer } from "mobx-react";
import { useState } from "react";

import DateFilter from "./section/DateFilter/DateFilter";
import ReferralHistorySection from "../components/ReferralHistorySection/ReferralHistorySection";
import Pagination from "components/UI/Pagination/Pagination";

import { useMatchMedia } from "utils/hooks/use-match-media";
import { REFERRAL_INVITED_EMPTY_DESCRIPTION, REFERRAL_INVITED_EMPTY_TITLE } from "../referralConstants";

import "./Invited.scss";

const Invited = observer(({ store }) => {
  const { isMobile } = useMatchMedia();
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const filteredAndSortedData = store.sortByDate(
    store.invitedData.filter((item) => item.status === "done")
  );

  const getCurrentPageData = () => {
    if (isMobile) {
      return filteredAndSortedData.slice(0, currentPage * itemsPerPage);
    }
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredAndSortedData.slice(startIndex, startIndex + itemsPerPage);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <section className="invited">
      <h3 className="invited__title">Приглашённые пользователи</h3>

      <div className="invited__container">
        <div className="invited__sort">
          {!isMobile && <DateFilter store={store} />}
          {/* TODO: DropdownList */}
          {!isMobile && (
            <div className="invited__sort_other">
              <div className="invited__sort_other-params">
                Последние 30 дней
              </div>
              <div className="invited__sort_other-params">
                По пользователям
              </div>
              <div className="invited__sort_other-params">
                По дате регистрации
              </div>
            </div>
          )}
        </div>

        <div className={`invited__history ${filteredAndSortedData.length < 5 ? "other-height" : ""}`}>
          <ReferralHistorySection
            data={getCurrentPageData()}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            isMobile={isMobile}
            type="invited"
            emptyTitle={REFERRAL_INVITED_EMPTY_TITLE}
            emptyDescription={REFERRAL_INVITED_EMPTY_DESCRIPTION}
          />
        </div>
      </div>
      {filteredAndSortedData.length > itemsPerPage && (
        <Pagination
          totalItems={filteredAndSortedData.length}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          isMobile={isMobile}
        />
      )}
    </section>
  );
});

export default Invited;
