import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import { Popup } from "components/Popup/Popup";
import Button from "components/UI/Button/Button";
import CheckBox from "components/UI/CheckBox/CheckBox";
import { TextInput } from "components/UI/TextInput/TextInput";

import popupStore from "components/Popup/PopupStore";
import referralStore from "pages/Referral/ReferralStore";

import { useMatchMedia } from "utils/hooks/use-match-media";

import "./ReferralWithdrawalModal.scss";

export const ReferralWithdrawalModal = observer(() => {
  const { isMobile } = useMatchMedia();

  const handleInputChange = (e) => {
    const value = e.target.value;
    const regex = /^[1-9]?\d*$/;
    if (regex.test(value)) {
      referralStore.setAmount(value);
    }
  };

  return (
    <Popup modalType="referral-withdrawal-modal" title={isMobile ? "Вывод средств" : <>Вывод средств <br /> с партнёрской программы</>}>
      <div className="referral-withdrawal-modal__info">
        <p className="referral-withdrawal-modal__info-title">{isMobile ? "Введите сумму перевода" : "Введите сумму перевода или выберите из списка"}</p>
        <TextInput
          type="text"
          inputmode="numeric"
          placeholder={isMobile ? "Сумма перевода" : "Сумма"}
          maxLength={7}
          value={referralStore.amount}
          onChange={handleInputChange}
          onKeyDown={(e) => { if (e.key === "0" && popupStore.amount === "") e.preventDefault() }}
        />
        <div className="referral-withdrawal-modal__info_plate">
          <div className="referral-withdrawal-modal__info_plate-sum">
            <p>Доступно к выводу:</p>
            <p className="referral-withdrawal-modal__info_plate-sum-amount">{referralStore.availableWithdrawal.toLocaleString()} &#8381;</p>
          </div>
          <div className="referral-withdrawal-modal__info_plate-params">
            <p>Минимальная сумма для вывода: {referralStore.minWithdrawalAmount.toLocaleString()} &#8381;</p>
            <p>Максимальная сумма для вывода: {referralStore.maxWithdrawalAmount.toLocaleString()} &#8381;</p>
          </div>
        </div>
      </div>

      <div className="referral-withdrawal-modal__consent-container">
        <CheckBox
          title={<>Согласие на оплату в соответствии с <Link to="/docs/offer.pdf" target="_blank" className="referral-withdrawal-modal__link">договором оферты</Link></>}
          checked={referralStore.agreedWithOffer}
          onClick={() => referralStore.setAgreedWithOffer(!referralStore.agreedWithOffer)}
        />
        <div className="referral-withdrawal-modal__buttons">
          <Button
            title="Отмена"
            className="referral-withdrawal-modal__button-cancel"
            onClick={() => {
              popupStore.close();
              referralStore.setAmount("");
              referralStore.setAgreedWithOffer(false);
            }}
          />
          <Button
            title="Указать реквизиты"
            classes={["wide"]}
            onClick={() => {}}
            disabled={
              !referralStore.agreedWithOffer ||
              referralStore.totalAmount < referralStore.amount ||
              referralStore.amount === ""
            }
          />
        </div>
      </div>
    </Popup>
  );
});
