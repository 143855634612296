import { observer } from "mobx-react";

import { Popup } from "components/Popup/Popup";
import Button from "components/UI/Button/Button";

import popupStore from "components/Popup/PopupStore";
import popupBoxStore from "PopupBox/PopupBoxStore";

import client from "client";

import "./ReferralDialogModal.scss";

export const ReferralDialogModal = observer(() => {
  return (
    <Popup modalType="referral-dialog-modal" title="Вы хотите стать рефералом этого пользователя?">
      <div className="referral-dialog-modal__buttons">
        <Button
          title="Нет"
          className="referral-dialog-modal__button-cancel"
          onClick={() => popupStore.close()}
        />
        <Button
          title="Да"
          classes={["wide"]}
          onClick={async () => {
            const res = await client.becomeReferral(popupStore.sponsorId);
            popupStore.close();
            popupBoxStore.showInfo(res.message);
          }}
        />
      </div>
    </Popup>
  );
});
