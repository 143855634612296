import { observer } from "mobx-react";
import { useState } from "react";

import AmountSection from "./section/AmountSection/AmountSection";
import InfoSection from "./section/InfoSection/InfoSection";
import ReferralHistorySection from "../components/ReferralHistorySection/ReferralHistorySection";
import Pagination from "components/UI/Pagination/Pagination";

import popupStore from "components/Popup/PopupStore";

import { useMatchMedia } from "utils/hooks/use-match-media";
import { REFERRAL_WITHDRAWING_EMPTY_DESCRIPTION, REFERRAL_WITHDRAWING_EMPTY_TITLE } from "../referralConstants";

import "./Withdrawing.scss";

const Withdrawing = observer(({ store }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { isMobile } = useMatchMedia();
  const itemsPerPage = 5;
  const sortedData = store.sortByDate(store.invitedData);

  const getCurrentPageData = () => {
    if (isMobile) {
      return sortedData.slice(0, currentPage * itemsPerPage);
    }
    const startIndex = (currentPage - 1) * itemsPerPage;
    return sortedData.slice(startIndex, startIndex + itemsPerPage);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <section className="withdrawing">
      <div className="withdrawing__container">
        <h3 className="withdrawing__title">Вывод средств</h3>
        <div className="withdrawing__sum">
          <AmountSection  store={store} onWithdraw={() => popupStore.open("referral-withdrawal-modal")} />
          <InfoSection store={store} />
        </div>
      </div>

      <div className="withdrawing__container">
        <h3 className="withdrawing__title">История операций</h3>
        <div className={`withdrawing__history ${sortedData.length < 5 ? "other-height" : ""}`}>
          <ReferralHistorySection
            data={getCurrentPageData()}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            isMobile={isMobile}
            type="withdrawing"
            emptyTitle={REFERRAL_WITHDRAWING_EMPTY_TITLE}
            emptyDescription={REFERRAL_WITHDRAWING_EMPTY_DESCRIPTION}
          />
        </div>
        {sortedData.length > itemsPerPage && (
          <Pagination
            totalItems={sortedData.length}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            isMobile={isMobile}
          />
        )}
      </div>
    </section>
  );
});

export default Withdrawing;
