import { observer } from "mobx-react";

import Plate from "components/UI/Plate/Plate";

import popupBoxStore from "PopupBox/PopupBoxStore";

import {
  REFERRAL_LINK_SUBTITLE,
  REFERRAL_LINK_TITLE,
} from "pages/Referral/referralConstants";

import "./ReferralLink.scss";

const ReferralLink = observer(({ store }) => {

  const handleCopyCurrentLink = (link) => {
    if (!link)
      return;
    navigator.clipboard.writeText(link);
    popupBoxStore.showInfo("Ссылка скопирована");
  };

  return (
    <Plate className="referral__link">
      <div className="referral__link-description">
        <h3 className="referral__link-title">{REFERRAL_LINK_TITLE}</h3>
        <p className="referral__link-subtitle">{REFERRAL_LINK_SUBTITLE}</p>
      </div>
      <div className="referral__link-container">
        <p className="referral__link-item">{store.referralLink ?? ''}</p>
        <div className="referral__link-icon" onClick={() => handleCopyCurrentLink(store.referralLink)} />
      </div>
    </Plate>
  );
});

export default ReferralLink;
