import { observer } from "mobx-react";

import TextBlock from "components/UI/TextBlock/TextBlock";

import { REFERRAL_WITHDRAWAL_CONDITIONS_TITLE } from "pages/Referral/referralConstants";

import "./InfoSection.scss";

const InfoSection = observer(({ store }) => {
  const infoItems = [
    { label: "Минимальная сумма вывода: ", value: `${Number(store.minWithdrawalAmount).toLocaleString()} ₽` },
    { label: "Максимальная сумма вывода: ", value: `${Number(store.maxWithdrawalAmount).toLocaleString()} ₽` },
    { label: "Комиссия: ", value: `${store.commission}%` },
    { label: "Срок получения платежа: ", value: `${store.deadlinePaymentReceipt} дней` },
  ];

  return (
    <div className="withdrawing__info-section">
      <p className="withdrawing__info-section-title">{REFERRAL_WITHDRAWAL_CONDITIONS_TITLE}</p>
      <div className="withdrawing__info-section-conditions">
        {infoItems.map((item, index) =>
          <TextBlock
            key={index}
            title={item.label}
            text={item.value}
            titleClassName="withdrawing__info-section-conditions-title"
          />
        )}
      </div>
    </div>
  );
});

export default InfoSection;
