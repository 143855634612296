import Faqed from "components/UI/Faqed/Faqed";

import "./AmountItem.scss";

const AmountItem = ({ label, value, faq }) => {
  return (
    <div className="amount-item">
      <Faqed text={faq}>
        {label}
        <p>{value.toLocaleString()} &#8381;</p>
      </Faqed>
    </div>
  );
};

export default AmountItem;
