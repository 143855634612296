import PropertyRow from "pages/Referral/components/UI/PropertyRow/PropertyRow";
import EmptySection from "pages/Referral/components/UI/EmptySection/EmptySection";
import ValueRow from "pages/Referral/components/UI/ValueRow/ValueRow";

import "./ReferralHistorySection.scss";

const ReferralHistorySection = ({ data,
  currentPage,
  itemsPerPage,
  isMobile,
  type,
  emptyTitle,
  emptyDescription,
  propertyRowConfig = {},
}) => {

  const calculateEnrollment = (item) => {
    return type === "invited" ? (item.income * 0.015).toFixed(2) : null;
  };

  const calculateAccount = (index) => {
    if (type !== "invited") return null;
    return isMobile 
      ? index + 1 
      : (currentPage - 1) * itemsPerPage + index + 1;
  };

  const defaultPropertyRowConfig = {
    userText: "Пользователь",
    dateText: "Дата регистрации",
    amountText: "Сумма",
    ...(type === "withdrawing" && { statusText: "Статус" }),
  };

  const finalPropertyRowConfig = {
    ...defaultPropertyRowConfig,
    ...propertyRowConfig,
  };

  return (
    <>
      {!isMobile && (
        <PropertyRow {...finalPropertyRowConfig} type={type} />
      )}
      {!data?.length ? (
        <EmptySection title={emptyTitle} text={emptyDescription} />
       ) : (
        data.map((item, index) => (
          <ValueRow
            key={index}
            {...item}
            isMobile={isMobile}
            type={type}
            account={calculateAccount(index)}
            enrollment={calculateEnrollment(item)}
          />
        ))
      )}
    </>
  );
};

export default ReferralHistorySection;