import { AppError } from './errors';

export class InvalidIdError extends AppError {
  constructor(message) {
    super(message || 'Некорректный идентификатор ресурса');
  }
}

export class Id extends Number {
  /**
   * @param {number|string} value
   * @param {} errorType Тип ошибки при неверном формате идентификатора.
   */
  constructor(value, errorType = InvalidIdError) {
    super(value);

    const val = this.valueOf();
    if (!Number.isInteger(val) || val <= 0)
      throw new errorType();
  }
}

export class InvalidUserIdError extends InvalidIdError {
  constructor(message) {
    super(message || 'Некорректный идентификатор пользователя');
  }
}

export class UserId extends Id {
  constructor(value, errorType = InvalidUserIdError) {
    super(value, errorType);
  }
}

export class InvalidSponsorIdError extends InvalidUserIdError {
  constructor(message) {
    super(message || 'Некорректный идентификатор спонсора');
  }
}

export class SponsorId extends UserId {
  constructor(value, errorType = InvalidSponsorIdError) {
    super(value, errorType);
  }
}

/**
 * @param {string} path
 * @param {} type
 * @returns {?Id}
 */
export function extractIdFromLastSegment(path, type = Id) {
  const parts = path.split('/');
  if (!parts.length)
    return null;
  return new type(parts[parts.length - 1]);
}
