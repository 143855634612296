import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";

import { IsMobileHeader } from "./IsMobileHeader/IsMobileHeader";
import { IsDesktopHeader } from "./IsDesktopHeader/IsDesktopHeader";

import AppStore from "AppStore";
import dropDownMenuStore from "components/DropDownMenu/DropDownMenuStore";
import popupStore from "components/Popup/PopupStore";
import messageBoxStore from "MessageBox/MessageBoxStore";

import { useMatchMedia } from "../utils/hooks/use-match-media";
import { setCookie } from "utils/cookie";

import "./Header.scss";

const ONLY_LOGO_PATHS = ["/signin", "/signup", "/restore", "/reset"];

function isOnlyLogo(pathname) {
  return ONLY_LOGO_PATHS.includes(pathname) || pathname.includes('/invite/');
}

export const Header = observer(() => {
  const { isMobile } = useMatchMedia();
  const { pathname } = useLocation();

  const onlyLogo = isOnlyLogo(pathname);

  const headerForDesktop = pathname === "/projects" || pathname.startsWith("/blog");

  const popularServicesLinks = AppStore.marketStores.reduce((calculators, marketStore) => {
    calculators.push(...marketStore.calculators.filter(calculator => calculator.mode.rating));
    return calculators;
  }, []).sort((a, b) => b.mode.rating - a.mode.rating).map(calculator => {
    const mode = calculator.mode;
    const path = mode.path;
    const marketStore = AppStore.findMarketStoreById(calculator.market.id);
    return {
      href: path,
      label: mode.navigateTitle,
      logo: marketStore.market.icon,
      icon: mode.icon,
      status: mode.status,
      onClick: () => {
        AppStore.selectMarketStoreAndCalculatorByPath(path);
        window.scroll(0, 0);
      }
    }
  });

  const saveRedirectUrl = () => {
    const currentUrl = window.location.pathname + window.location.search;
    setCookie("redirect_url", currentUrl, { expires: 1 });
  };

  const toggleDropdownListServices = () => {
    AppStore.setIsDropdownLinksOpen(!AppStore.isDropdownLinksOpen);

    setTimeout(() => {
      dropDownMenuStore.setDisplayFormat(isMobile ? "row" : "grid");
      AppStore.clearSearchQuery();
    }, 300);
  };

  const closeDropdownListServices = () => {
    AppStore.setIsDropdownLinksOpen(false);

    setTimeout(() => {
      dropDownMenuStore.setDisplayFormat(isMobile ? "row" : "grid");
      AppStore.clearSearchQuery();
    }, 300);
  }

  const pathToFaq = (AppStore.isMarketPage(pathname) ? pathname : '/povedencheskie-faktory-na-obyavleniya') + '#faq';

  const toggleTelegramNotifications = async () => {
    popupStore.open('bot-modal');
    if (!AppStore.telegramId) {
      try {
        await AppStore.turnOnNotifications();
      } catch (error) {
        messageBoxStore.showError(error || "Произошла ошибка при формировании кода");
      }
    }
  };

  return (
    <header className="header">
      {isMobile && !headerForDesktop ? (
        <IsMobileHeader
          appStore={AppStore}
          dropStore={dropDownMenuStore}
          onlyLogo={onlyLogo}
          popularServices={popularServicesLinks}
          locationPathname={pathname}
          saveRedirectUrl={saveRedirectUrl}
          pathToFaq={pathToFaq}
          isMobile={isMobile}
          headerForDesktop={headerForDesktop}
          isDropdownListServices={AppStore.isDropdownLinksOpen}
          toggleDropdownListServices={toggleDropdownListServices}
          onCloseDropdownListServices={closeDropdownListServices}
          toggleTelegramNotifications={toggleTelegramNotifications}
        />
      ) : (
        <IsDesktopHeader
          appStore={AppStore}
          dropStore={dropDownMenuStore}
          onlyLogo={onlyLogo}
          popularServices={popularServicesLinks}
          locationPathname={pathname}
          saveRedirectUrl={saveRedirectUrl}
          pathToFaq={pathToFaq}
          isDropdownListServices={AppStore.isDropdownLinksOpen}
          toggleDropdownListServices={toggleDropdownListServices}
          onCloseDropdownListServices={closeDropdownListServices}
          toggleTelegramNotifications={toggleTelegramNotifications}
        />
      )}
    </header>
  );
});
