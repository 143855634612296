import "./EmptySection.scss";

const EmptySection = ({ title, text }) => {
  return (
    <div className="empty-section">
      <h3 className="empty-section__title">{title}</h3>
      <p className="empty-section__text">{text}</p>
    </div>
  );
};

export default EmptySection;