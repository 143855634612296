import { StatusCodes } from "http-status-codes";

import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Button from "components/UI/Button/Button";

import "./NotFoundPages.scss";

function getUserMessage(code) {
  if (code >= 500) {
    return "на сервисе произошла ошибка, мы ее уже устраняем";
  } else if (code >= 400) {
    if (code === StatusCodes.NOT_FOUND)
      return "страница не найдена: ее или уже нет, или никогда не было, или в ссылке опечатка";
    return "сервер не смог обработать ваш запрос, мы уже разбираемся";
  } else if (code >= 300) {
    return "страница перемещена: вы будете перенаправлены на другой адрес";
  }
  return "вы не должны были сюда попасть, мы уже разбираемся";
}

const NotFoundPages = ({ code, message }) => {
  const navigate = useNavigate();

  let { pathname } = useLocation();
  if (pathname.startsWith('/'))
    pathname = pathname.substring(1);
  if (pathname.endsWith('/'))
    pathname = pathname.substring(0, pathname.length - 1);

  if (!code)
    code = Number(pathname);
  if (isNaN(code) || code < 100 || code >= 600)
    code = StatusCodes.NOT_FOUND;

  if (!message)
    message = `— в переводе на человеческий это означает, что\xa0${getUserMessage(code)}\xa0 😌`;

  return (
    <section className="page-error">
      <Helmet>
        <title>{`Ошибка ${code} | Boostclick`}</title>
        <meta name="description" content={`Ошибка ${code} на сервисе Boostclick`} />
        <meta name="robots" content="noindex" />
      </Helmet>

      <h1 className="page-error__title">{code}</h1>
      <p className="page-error__subtitle">{message}</p>

      <Button title="На главную" onClick={() => navigate("/")} />
    </section>
  );
};

export default NotFoundPages;
