import "./ValueRow.scss";

const ValueRow = ({
  login,
  id,
  accountType,
  account,
  date,
  status,
  enrollment,
  income,
  type,
  isMobile,
}) => {
  const statusTextMap = {
    created: "Создана",
    done: "Проведена",
    rejected: "Отклонено",
    process: "В процессе",
    cancelled: "Отменена",
  };

  const getStatusText = (status) => statusTextMap[status] || status;

  return (
    <div className={`table-row-${type}`}>
      {type === "withdrawing" &&
        <>
          {isMobile ? (
            <div className="table-row-item__mobile">
              <div className="table-row-item__info">
                <p className="table-row-item">{`${login} (ID: ${id}, ${accountType})`}</p>
                <p className="table-row-item">{date}</p>
              </div>
              <div className="table-row-item__info">
                <p className="table-row-item">{Number(income).toLocaleString()} &#8381;</p>
                <p className={`table-row-item ${status}`}>{getStatusText(status)}</p>
              </div>
            </div>
          ) : (
            <>
              <p className="table-row-item">{date}</p>
              <p className="table-row-item">{`${login} (ID: ${id}, ${accountType})`}</p>
              <p className="table-row-item">{Number(income).toLocaleString()} &#8381;</p>
              <p className={`table-row-item ${status}`}>{getStatusText(status)}</p>
            </>
          )}
        </>
      }
      {type === "invited" &&
        <>
          {isMobile ? (
            <div className="table-row-item__mobile">
              <div className="table-row-item__info">
                <p className="table-row-item">{account}.&nbsp;&nbsp;{`${login} (ID: ${id}, ${accountType})`}</p>
                <p className="table-row-item">{date}</p>
              </div>
              <p className={`table-row-item ${status}`}>{`+ ${enrollment} ₽`}</p>
            </div>
          ) : (
            <>
              <p className="table-row-item">{account}.&nbsp;&nbsp;{`${login} (ID: ${id}, ${accountType})`}</p>
              <p className="table-row-item">{date}</p>
              <p className={`table-row-item ${status}`}>{`+ ${enrollment} ₽`}</p>
            </>
          )}
        </>
      }
    </div>
  );
};

export default ValueRow;
