import { observer } from "mobx-react";

import Button from "components/UI/Button/Button";
import AmountItem from "pages/Referral/components/UI/AmountItem/AmountItem";

import "./AmountSection.scss";

const AmountSection = observer(({ store, onWithdraw }) => {
  const amountItems = [
    { label: "Доступно для вывода", value: store.availableWithdrawal ? `${store.availableWithdrawal}` : "—", faq: "" },
    { label: "В процессе вывода", value: store.processWithdrawal ? `${store.processWithdrawal}` : "—", faq: "" },
    { label: "Выведено средств", value: store.withdrawal ? `${store.withdrawal}` : "—", faq: "" },
    { label: "Всего заработано", value: store.totalEarned ? `${store.totalEarned}` : "—", faq: "" },
  ];

  return (
    <div className="withdrawing__amount-section">
      <div className="withdrawing__amount-section_balance">
        <div>
          <p className="withdrawing__amount-section_balance-text">Текущий баланс</p>
          <p className="withdrawing__amount-section_balance-count">{store.currentBalance ? Number(store.currentBalance).toLocaleString() : "—"} &#8381;</p>
        </div>
        <Button title="Вывести средства" onClick={onWithdraw} disabled={!store.currentBalance} />
      </div>
      <div className="withdrawing__amount-section_total">
        {amountItems.map((item, index) => <AmountItem key={index} {...item} />)}
      </div>
    </div>
  );
});

export default AmountSection;