import { makeAutoObservable } from "mobx";

import AppStore from "AppStore";

/**
 * @property {number} availableWithdrawal Доступно для вывода.
 * @property {number} processWithdrawal В процессе вывода.
 * @property {number} withdrawal Выведено средств.
 * @property {number} totalEarned Всего заработано.
 *
 * @property {number} minWithdrawalAmount Минимальная сумма вывода.
 * @property {number} maxWithdrawalAmount Максимальная сумма вывода.
 * @property {number} commission Комиссия.
 * @property {number} deadlinePaymentReceipt Комиссия.
 *
 * @property {number} minReferrals Минимальное кол-во приглашенных.
 * @property {number} maxReferrals Максимальное кол-во приглашенных.
 * @property {number} rewardPerReferral Приход с 1 приглашенного.
 * @property {number} referralCount Начальное значение приглашенных пользователей.
 */
class ReferralStore {
  constructor() {
    makeAutoObservable(this);
  }

  invitedData = [
    {
      login: "nickname",
      id: "1736608",
      accountType: "Базовый",
      date: "12.12.22 21:21",
      status: "created", // создана
      income: "120",
    },
    {
      login: "rznccc@mail.ru",
      id: "1736608",
      accountType: "Базовый",
      date: "12.12.22 20:21",
      status: "done", // Отправлено
      income: "990",
    },
    {
      login: "7162121@mail.ru",
      id: "1736608",
      accountType: "Базовый",
      date: "12.12.22 15:56",
      status: "rejected", // отклонено
      income: "5550",
    },
    {
      login: "lis-test@mail.ru",
      id: "1736608",
      accountType: "Базовый",
      date: "14.09.22 03:42",
      status: "process", // На проверке
      income: "588",
    },
    {
      login: "nickname",
      id: "1736608",
      accountType: "Базовый",
      date: "15.05.22 22:49",
      status: "cancelled", // отменена
      income: "234",
    },
    {
      login: "nickname",
      id: "1736608",
      accountType: "Базовый",
      date: "15.05.24 22:49",
      status: "process", // в процессе
      income: "350",
    },
    {
      login: "nickname",
      id: "1736608",
      accountType: "Базовый",
      date: "15.05.23 22:49",
      status: "done", // проведена
      income: "777",
    },
  ];

  currentBalance = 0;
  availableWithdrawal = 0;
  processWithdrawal = 0;
  withdrawal = 0;
  totalEarned = 0;

  minWithdrawalAmount = 1000;
  maxWithdrawalAmount = 30000;
  commission = 6;
  deadlinePaymentReceipt = 7;

  minReferrals = 1;
  maxReferrals = 5000;
  rewardPerReferral = 0.25;

  get referralLink() {
    const userId = AppStore.userId;
    if (!userId)
      return undefined;
    const host = process.env.REACT_APP_HOST_ADDRESS ?? 'https://boostclick.ru';
    const link = new URL(`/invite/${userId}`, host);
    return link.toString();
  }

  referralCount = 650;
  setReferralCount(count) {
    if (typeof referralCount !== "number") count = Number(count);
    this.referralCount = Math.min(
      Math.max(count, this.minReferrals),
      this.maxReferrals
    );
  }

  get calculatedReward() {
    return (this.referralCount * this.rewardPerReferral).toFixed(2);
  }

  amount = "";
  setAmount(amount) {
    this.amount = amount;
  }

  agreedWithOffer = false;
  setAgreedWithOffer(value) {
    this.agreedWithOffer = value;
  }

  startDate = null;
  setStartDate = (date) => {
    this.startDate = date;
  };

  endDate = null;
  setEndDate = (date) => {
    this.endDate = date;
  };

  sortByDate = (data) => {
    return [...data].sort((a, b) => {
      const [dayA, monthA, yearA] = a.date.split(" ")[0].split(".");
      const [dayB, monthB, yearB] = b.date.split(" ")[0].split(".");
      const fullYearA = "20" + yearA;
      const fullYearB = "20" + yearB;
      const dateA = new Date(fullYearA, monthA - 1, dayA);
      const dateB = new Date(fullYearB, monthB - 1, dayB);
      return dateB - dateA;
    });
  };
}

const referralStore = new ReferralStore();
export default referralStore;
