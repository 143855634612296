import './PropertyRow.scss';

const PropertyRow = ({ userText, dateText, amountText, statusText, type }) => {
  return (
    <div className={`property-row-${type}`}>
      {type === 'withdrawing' &&
        <>
          <p className="property-row-item">{dateText}</p>
          <p className="property-row-item">{userText}</p>
          <p className="property-row-item">{amountText}</p>
          <p className="property-row-item">{statusText}</p>
        </>
      }
      {type === 'invited' &&
        <>
          <p className="property-row-item">{userText}</p>
          <p className="property-row-item">{dateText}</p>
          <p className="property-row-item">{amountText}</p>
        </>
      }
    </div>
  );
}

export default PropertyRow;
