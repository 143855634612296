export class Project {

  constructor(project) {
    Object.assign(this, project);

    if (!project.screenshot)
      this.screenshot = false;
    if (!project.cancelling)
      this.cancelling = false;
  }

  getDefaultTitle() {
    if (!this.type) return "Активность на площадке";
    if (this.type === "AVITO") return "Активность ОБЪЯВЛЕНИЯ";
    return `Активность на ${this.type}`;
  }

  get titleToShow() {
    let t = this.title || this.getDefaultTitle();
    const maxLength = 16;

    if (t.length > maxLength) 
      t = t.substring(0, maxLength) + "...";

    return `${t} (#${this.id})`;
  }

  get fullTitle() {
    let t = this.title || this.getDefaultTitle();
    return `${t} (#${this.id})`;
  }

  // TODO: delete, update, pay, finish, archive...
}
