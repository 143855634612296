import coin from "assets/images/referral/coin.svg";
import copy from "assets/images/referral/copy.svg";
import letter from "assets/images/referral/letter.svg";
import step1 from "assets/images/dashboard/howtoworks/step1.svg";
import step2 from "assets/images/dashboard/howtoworks/step2.svg";
import step3 from "assets/images/dashboard/howtoworks/step3.svg";

export const REFERRAL_TITLE = "Партнерская программа";
export const REFERRAL_SUBTITLE = <>Зарабатывайте до <span>15%*</span> от суммы пополнения кабинета</>;
export const REFERRAL_DISCLAIMER = <>*ПФ - 15%; Подписчики - 10%; Комментарии - 5%; Просмотры - 10%; Отзывы - 5% <br /> Важно: Бонусы начисляются только за принятые задания. При отказе от работы исполнителя или возврате средств рефералом бонусы за непринятые задания не начисляются.</>;
export const REFERRAL_SHARE_TITLE = "Расскажите своим друзьям, знакомым и коллегам о нашем сайте и заработайте на этом!";
export const REFERRAL_SHARE_SUBTITLE = "Все подробности и правила партнёрской программы доступны в разделе";
export const REFERRAL_HOW_IT_WORKS_TITLE = "🤔 Как это работает?";
export const REFERRAL_SLIDER_TITLE = "Узнайте сколько вы сможете заработать";
export const REFERRAL_LINK_TITLE = "Ваша реферальная ссылка";
export const REFERRAL_LINK_SUBTITLE = "Чтобы пригласить пользователя, отправьте ссылку или разместите её на своём сайте, блоге или странице в социальных сетях.";
export const REFERRAL_HOWITWORKS_ADVICES = "Реферальная программа доступна только для юридических лиц и самозанятых. Физические лица в программе не принимают участие.";

export const REFERRAL_WITHDRAWING_EMPTY_TITLE = "Тут пусто";
export const REFERRAL_WITHDRAWING_EMPTY_DESCRIPTION = "Истории операций нет, пока никто из приглашённых пользователей не принял задание";
export const REFERRAL_WITHDRAWAL_CONDITIONS_TITLE = "Условия вывода средств";

export const REFERRAL_INVITED_EMPTY_TITLE = `Пока никого нет :(`;
export const REFERRAL_INVITED_EMPTY_DESCRIPTION = "Не опускайте руки! Вы делаете все правильно, и вскоре ваши друзья откликнутся. Главное напоминайте им о вашей ссылке 😉";

export const promoData = [
  {
    icon: { src: copy, alt: "Иконка шага 1" },
    step: { src: step1, alt: "Номер шага 01" },
    title: "Копируйте",
    text: "Скопируйте реферальную ссылку платформы Boostclick",
  },
  {
    icon: { src: letter, alt: "Иконка шага 1" },
    step: { src: step2, alt: "Номер шага 01" },
    title: "Отправляйте",
    text: "Поделитесь ссылкой на своём сайте, блоге или странице в соц. сетях",
  },
  {
    icon: { src: coin, alt: "Иконка шага 1" },
    step: { src: step3, alt: "Номер шага 01" },
    title: "Зарабатывайте",
    text: "Зарабатывайте и выводите деньги за регистрацию пользователей",
  },
];
