import { Link } from "react-router-dom";

import { REFERRAL_TITLE, REFERRAL_SUBTITLE, REFERRAL_DISCLAIMER, REFERRAL_SHARE_TITLE, REFERRAL_SHARE_SUBTITLE } from "pages/Referral/referralConstants";

import "./ReferralPromo.scss";

const ReferralPromo = ({ isMobile }) => (
  <section className="referral__promo">
    <div className="referral__promo_about">
      <div className="referral__promo_about-contain">
        <h1 className="referral__promo_about-title">{REFERRAL_TITLE}</h1>
        <h2 className="referral__promo_about-text">{REFERRAL_SUBTITLE}</h2>
      </div>
      {!isMobile && <p className="referral__promo_about-description">{REFERRAL_DISCLAIMER}</p>}
    </div>

    <div className="referral__promo_share">
      <p className="referral__promo_share-title">{REFERRAL_SHARE_TITLE}</p>
      {!isMobile ? <p className="referral__promo_share-subtitle">{REFERRAL_SHARE_SUBTITLE} <Link to="#" className="referral__promo_share-link">FAQ</Link>.</p> : <Link to="#" className="referral__promo_share-link">Узнать подробнее</Link>}
    </div>
  </section>
);

export default ReferralPromo;