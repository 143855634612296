import { observer } from "mobx-react";
import { useState } from "react";
import { Helmet } from "react-helmet";

import HowItWorks from "components/HowItWorks/HowItWorks";
import ReferralPromo from "./components/ReferralPromo/ReferralPromo";
import ReferralSlider from "./components/ReferralSlider/ReferralSlider";
import ReferralLink from "./components/ReferralLink/ReferralLink";
import Withdrawing from "./Withdrawing/Withdrawing";
import Invited from "./Invited/Invited";
import Answers from "components/Answers/Answers";

import AppStore from "AppStore";
import referralStore from "./ReferralStore";

import { useMatchMedia } from "utils/hooks/use-match-media";

import {
  REFERRAL_DISCLAIMER,
  REFERRAL_HOWITWORKS_ADVICES,
  REFERRAL_HOW_IT_WORKS_TITLE,
  promoData,
} from "./referralConstants";

import "./Referral.scss";

export const Referral = observer(() => {
  const { isMobile } = useMatchMedia();
  const [isExpanded, setIsExpanded] = useState(false);
  const calculator = AppStore.currentMarketStore.currentCalculator;
  const { mode } = calculator;

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <main className="referral">

      <Helmet>
        <title>Реферальная программа — Boostclick</title>
        <meta name="description" content="Рекомендуйте наши услуги друзьям и знакомым и получайте вознаграждения за каждого привлечённого клиента. Участвовать просто: делитесь уникальной ссылкой, следите за прогрессом в личном кабинете и наслаждайтесь бонусами за свою активность. Начните зарабатывать уже сегодня!" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>

      <div className="referral__inner">
        <ReferralPromo isMobile={isMobile} />
        {isMobile &&
          <div className="referral__button-open">
            <p className={`referral__button-open-text ${isExpanded ? "other-color" : ""}`} onClick={toggleExpanded}>{REFERRAL_HOW_IT_WORKS_TITLE}</p>
            {isExpanded &&
              <HowItWorks
                isExpanded={isExpanded}
                toggleExpanded={toggleExpanded}
                title={REFERRAL_HOW_IT_WORKS_TITLE}
                stepData={promoData}
                additionalContent={<ReferralSlider store={referralStore} />}
                advicesText={REFERRAL_HOWITWORKS_ADVICES}
              />
            }
          </div>
        }
        {!isMobile &&
          <HowItWorks
            isExpanded={isExpanded}
            toggleExpanded={toggleExpanded}
            title={REFERRAL_HOW_IT_WORKS_TITLE}
            stepData={promoData}
            additionalContent={<ReferralSlider store={referralStore} />}
            advicesText={REFERRAL_HOWITWORKS_ADVICES}
          />
        }
        <ReferralLink store={referralStore} />
        <Withdrawing store={referralStore} />
        <Invited store={referralStore} />
        {isMobile && <p className="referral__disclaimer">{REFERRAL_DISCLAIMER}</p>}
        <Answers title={mode.titleFAQ} questions={mode.faq} />
      </div>

    </main>
  );
});
