import { observer } from "mobx-react";

import Slider from "components/UI/Slider/Slider";

import { REFERRAL_SLIDER_TITLE } from "pages/Referral/referralConstants";

import "./ReferralSlider.scss";

const ReferralSlider = observer(({ store }) => {
  return (
    <div className="referral__slider">
      <p className="referral__slider-title">{REFERRAL_SLIDER_TITLE}</p>
      <div className="referral__slider-container">
        <Slider
          min={store.minReferrals}
          max={store.maxReferrals}
          step={1}
          value={store.referralCount}
          handler={(value) => store.setReferralCount(value)}
          onMouseDown={() => {}}
          onTouchEnd={() => {}}
          thresholdColor="#FED400"
        />
        <div className="referral__slider-maxmin">
          <p>{store.minReferrals} человек</p>
          <p>{store.maxReferrals} человек</p>
        </div>
        <p className="referral__slider-sum">{store.calculatedReward} &#8381;</p>
      </div>
    </div>
  );
});

export default ReferralSlider;
